import React, { Suspense, lazy, useState, useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import "./App.scss";
import { Navigate } from "react-router-dom";
import Loading from "../utils/loading/Loading"
import Alert from '../utils/alert/Alert';
const PayoutsContainer = lazy(() => import('../views/payouts/container'));
const Login = lazy(() => import("../views/Login/container"));
const Profile = lazy(() => import("../views/Profile/container"));
const Training = lazy(() => import("../views/Training/container"));
const Contact = lazy(() => import("../views/Contact/container"));
const Welcome = lazy(() => import("../views/Welcome/welcome"));
const Traininglesson = lazy(() => import("../views/trainingLesson/container"));
const Quiz = lazy(() => import("../views/Quiz/container"));
const QuizRetake = lazy(() => import("../views/QuizRetake/container"));
const QuizSubmit = lazy(() => import("../views/Quiz/quizsubmit"));
const CreatePassword = lazy(() => import("../views/CreatePassword/container"));
const ChangePassword = lazy(() => import("../views/ChangePassword/container"));
const PasswordRecovery = lazy(() => import("../views/PasswordRecovery/container"));
const NextQueComponent = lazy(() => import("../views/Quiz/newque"));
const Dashboard = lazy(() => import("../views/Dashboard/container"));
const NewPassword = lazy(() => import("../views/Newpasswordfrgt/container"));
const Citations = lazy(() => import("../views/Citations/container"));
const CreateCitations = lazy(() => import("../views/CreateCitations/container"));
const EnforcementLiteContainer = lazy(() => import("../views/CreateCitations/EnforcementLiteContainer"));
const CreateMailInCitations = lazy(() => import("../views/mail_in/citation_form/container"));
const CitationsInformation = lazy(() => import("../views/CitationsInformation/container"));
const CitationsPayment = lazy(() => import("../views/CitationsPayment/container"));
const My_citations = lazy(() => import("../views/my_citations/container"));
const UpdateTag = lazy(() => import("../views/UpdateTag/container"));
const CitationsSummary = lazy(() => import("../views/CitationsSummary/container"));
const LookUp = lazy(() => import("../views/LookUp/container"));
const StripeOnBoardSuccessContainer = lazy(() => import("../views/stripeOnboardTaggr/container"));
const LotRules = lazy(() => import('../views/LotRules/container'))
const LotRulesWithOutCheckIn = lazy(() => import('../views/LotRuleWithoutCheckIn/container'))


function Wrapper(props) {
  const loadingState = useSelector(state => state?.loading)
  const alert = useSelector(state => state?.alert)

  const [lotData, setLotData] = useState(() => {
    const lotData = JSON.parse(localStorage.getItem("lotData"));
    return lotData !== null ? lotData : { lotId: "", lotname: "" };
  });

  useEffect(() => {
    localStorage.setItem("lotData", JSON.stringify(lotData));
  }, [lotData]);

  return (
    <>
      {alert?.status !== "hide" && <Alert />}
      {loadingState?.isLoading && <Loading />}
      <Router>
        <Routes>
          <Route exact path="/" element={<Navigate replace to="/login" />} />
          <Route
            path="/login"
            element={
              <Suspense fallback={<Loading />}>
                <Login props={props} />
              </Suspense>
            }
          />
          <Route
            path="/createpassword"
            element={
              <Suspense fallback={<Loading />}>
                <CreatePassword />
              </Suspense>
            }
          />
          <Route
            path="/passwordrecovery"
            element={
              <Suspense fallback={<Loading />}>
                <PasswordRecovery props={props} />
              </Suspense>
            }
          />
          <Route
            path="/welcome"
            element={
              <Suspense fallback={<Loading />}>
                <Welcome props={props} />
              </Suspense>
            }
          />
          <Route
            path="/traininglesson"
            element={
              <Suspense fallback={<Loading />}>
                <Traininglesson props={props} />
              </Suspense>
            }
          />
          <Route
            path="/quiz"
            element={
              <Suspense fallback={<Loading />}>
                <Quiz props={props} />
              </Suspense>
            }
          />
          <Route
            path="/quizretake"
            element={
              <Suspense fallback={<Loading />}>
                <QuizRetake props={props} />
              </Suspense>
            }
          />
          <Route
            path="/dashboard"
            element={
              <Suspense fallback={<Loading />}>
                <Dashboard props={props} setLotData={setLotData} />
              </Suspense>
            }
          />
          <Route
            path=""
            element={
              <Suspense fallback={<Loading />}>
                <NextQueComponent props={props} />
              </Suspense>
            }
          />
          <Route
            path="/profile"
            element={
              <Suspense fallback={<Loading />}>
                <Profile props={props} />
              </Suspense>
            }
          />
          <Route
            path="/training"
            element={
              <Suspense fallback={<Loading />}>
                <Training props={props} />
              </Suspense>
            }
          />
          <Route
            path="/contact"
            element={
              <Suspense fallback={<Loading />}>
                <Contact />
              </Suspense>
            }
          />
          <Route
            path="/changepassword"
            element={
              <Suspense fallback={<Loading />}>
                <ChangePassword />
              </Suspense>
            }
          />
          <Route
            path="/lot_rules/:id"
            element={
              <Suspense fallback={<Loading />}>
                <LotRulesWithOutCheckIn  />
              </Suspense>
            }
          />
          <Route
            path="/my_citations"
            element={
              <Suspense fallback={<Loading />}>
                <My_citations />
              </Suspense>
            }
          />
          <Route
            path="/citations"
            element={
              <Suspense fallback={<Loading />}>
                <Citations props={props} />
              </Suspense>
            }
          />
          <Route
            path="/create-citations"
            element={
              <Suspense fallback={<Loading />}>
                <CreateCitations props={props} lotData={lotData} />
              </Suspense>
            }
          />
          <Route
            path="/enforcement-lite/create-citations"
            element={
              <Suspense fallback={<Loading />}>
                <EnforcementLiteContainer props={props} lotData={lotData} />
              </Suspense>
            }
          />
          <Route
            path="/create-mail-in-citations"
            element={
              <Suspense fallback={<Loading />}>
                <CreateMailInCitations props={props} />
              </Suspense>
            }
          />
          <Route
            path="/citations-payment"
            element={
              <Suspense fallback={<Loading />}>
                <CitationsPayment props={props} />
              </Suspense>
            }
          />
          <Route
            path="/citations-information"
            element={
              <Suspense fallback={<Loading />}>
                <CitationsInformation props={props} />
              </Suspense>
            }
          />
          <Route
            path="/lot-rules/:id"
            element={
              <Suspense fallback={<Loading />}>
                <LotRules />
              </Suspense>
            }
          />
          <Route
            path="/password-reset/:id/:token"
            element={
              <Suspense fallback={<Loading />}>
                <NewPassword props={props} />
              </Suspense>
            }
          />
          <Route
            path="/update-tag"
            element={
              <Suspense fallback={<Loading />}>
                <UpdateTag props={props} lotData={lotData} />
              </Suspense>
            }
          />
          <Route
            path="/stripe/onboard"
            element={
              <Suspense fallback={<Loading />}>
                <StripeOnBoardSuccessContainer />
              </Suspense>
            }
          />
          <Route
            path="/citations-summary"
            element={<CitationsSummary props={props} />}
          />
          <Route path="/look-up" element={<LookUp props={props} />} />
          <Route
            path="/payouts"
            element={
              <Suspense fallback={<Loading />}>
                <PayoutsContainer props={props} />
              </Suspense>
            }
          />
        </Routes>
      </Router>
    </>
  );
}

export default Wrapper
