import AWS from 'aws-sdk';
import imageCompression from 'browser-image-compression';

export const s3 = new AWS.S3({
    accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_S3_KEY_SECRET,
    region: process.env.REACT_APP_REGION
  })
  
export const delayLoading = (state, ms) => setTimeout(() => state(false), ms ? ms : 2000);
export const storeDelayLoading = (dispatch, state, ms) => setTimeout(() => dispatch(state(false)), ms ? ms : 2000);
export const capitalize = s => (s && s[0]?.toUpperCase() + s?.slice(1)) || ""

// contain one to eight uppercase letters or digits, with no spaces or special characters. 
const licensePlateRegex = /^[A-Z0-9]{5,9}$/;

const restrict_special_characters_regex = /^[A-Z0-9 ]{0,}$/;
const no_space_regex = /^[A-Z0-9]{5,9}$/;

// return true or false based on the license plate validation for US
export const valid_license_plate = licensePlate => licensePlateRegex.test(licensePlate);
export const restrict_special_characters = licensePlate => restrict_special_characters_regex.test(licensePlate);



/**
 * 1000 METER DISTANCE FROM A LOT OR LAND
 */
export const __100METER = 0.000900901 * 20; // Is now 2000 meter
/**
 * THIS FUNCTION WILL RETURN TRUE IF LATITUDE AND LONGITUDE FALLS OUTSIDE THE LOT'S LATITUDE AND LOGITUDE
 */

export function OUTSIDE_LOT_BOUNDARY(latitude, longitude, lot_latitude, lot_longitude, ali) {
    const value = (
        (
            latitude > (lot_latitude + __100METER) || latitude < (lot_latitude - __100METER)
        ) || (
            longitude > (lot_longitude + __100METER) || longitude < (lot_longitude - __100METER)
        )
    );
    return ali === "true" ? false : value
}

export const getCurrentDateTime = (moment, tz) => {
    const now = moment().tz(tz);
    return now.format("YYYY-MM-DD_HH-mm-ss");
  };

export function getIsPointInsidePolygon([lat, lng], polygon, isDev) {
    let isInside = false;
    const x = lat
    const y = lng
  
    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
      const xi = polygon[i][0]
      const yi = polygon[i][1]
      const xj = polygon[j][0]
      const yj = polygon[j][1]
  
      const intersect = yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi
      if (intersect) isInside = !isInside
    }
    return isDev === "true" ? true : isInside
  }

export const handleUpload = async (selectedFile, imageName) => {
    if (!selectedFile) return;

    try {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 800, 
        useWebWorker: true,   
      };

      const compressedFile = await imageCompression(selectedFile, options);
      const uploadParams = {
        Bucket: process.env.REACT_APP_AWS_S3_BUCKET,
        Key: `${imageName}_${Date.now()}_${compressedFile?.name}`,
        Body: compressedFile,
        ContentType: compressedFile?.type,
        ACL: 'public-read',
      };
      const result = await s3.upload(uploadParams).promise();
      return result.Location
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };